//Assets
import styles from './styles';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SaveOutlined, InfoRounded, CheckOutlined } from "@material-ui/icons";
import moment from 'moment';
import classNames from 'classnames';

// UI Components
import { withStyles, AppBar, Tab, Tabs, Grid, Button, Typography, CircularProgress, Hidden, InputLabel, TextField } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, TabPanel, SaveContainer, Table, ConfirmDialog, Select } from 'components';
import { TextField as FormikTextField } from 'formik-material-ui';
import { Formik, Field, Form } from 'formik';
import { tabProps } from "components/TabPanel";
import Swal from 'sweetalert2';

//Services
import { WithTicket, WithNotifications, WithSession } from "hoc";
import requisitionsService from "services/requisitionsService";
import classificationsService from "services/classificationsService";

class ConfirmRequisition extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        // storageId: PropTypes.number
    };

    state = {
        selectedItems: [],
        requisition: {
            total: 0,
            totalRequested: 0
        },
        tab: 0,
        isConfirming: false,
        storages: [],
        classifications: [],
        storageIds: new Set(),
        classificationIds: new Set(),
        storageId: 0,
        classificationId: 0,
        searchItemText: "",
    };

    columns = [
        {
            title: "Descripción",
            field: 'itemPresentation.description',
            render: rowData => (
                <span className={this.props.classes.itemDescription}>
                    {rowData.itemPresentation.sapCode} - {this.getPresentationName(rowData.itemPresentation)}
                </span>
            )
        },
        {
            title: "Solicitado",
            field: 'quantity',
            render: rowData => <span>{rowData.quantity} {rowData.measurementUnit.abbreviation}</span>
        },
        // {
        //     title: "Costo",
        //     render: row => <span>${row.price}</span>
        // },
        {
            title: "Observaciones",
            field: 'notes',
        },
        {
            title: "Cantidad",
            field: 'suppliedQuantity',
            render: row => this.renderRowInput(row)
        },
        // {
        //     title: "Total",
        //     field: 'newTotal',
        //     render: row => <span>${row.newTotal || "0.00"}</span>
        // },
        {
            title: "Comentario",
            render: row => this.renderRowCommentInput(row)
        },
    ];

    componentDidMount = async () => {
        // if (!this.props.location.state || !this.props.location.state.storageId) {
        //     this.props.location.push('/confirm-requisitions');
        // }

        this.setState({
            storageId: this.props.location.state ? this.props.location.state.storageId : null,
        }, () => {
            this.getCatalogs();
            this.getRequisitionData();
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.storageId != this.state.storageId)
            this.setState({ classificationId: 0 });
    }

    getRequisitionData = async () => {
        let id = this.props.match.params.id;

        const response = await requisitionsService.getByIdWithDetails(id);
        if (response.ok && response.data.status != "Fail") {
            this.setState({
                requisition: {
                    ...response.data.data,
                    type: 'Pedido',
                    deliveryDateFormatted: moment(response.data.data.deliveryDate).format("DD/MM/YYYY"),
                    totalRequested: response.data.data.supplierRequisitionDetails.reduce((sum, i) => sum + i.total, 0),
                    supplierRequisitionDetails: response.data.data.supplierRequisitionDetails.map((i, index) => {
                        i.index = index;

                        if (!i.suppliedQuantity)
                            i.suppliedQuantity = "";

                        this.getRowTotal(i);

                        return i;
                    })
                }
            }, () => {
                let storageIds = new Set();
                let classificationIds = new Set();
                this.state.requisition.supplierRequisitionDetails.forEach(i => {
                    storageIds.add(i.supplierItem.storageId);
                    classificationIds.add(i.supplierItem.clasificationId);
                });

                this.setState({ storageIds, classificationIds }, () => {
                });

                window.setTimeout(() => {
                    this.previousFocus(1);
                }, 500);
            });
        }
    }

    getCatalogs = async () => {
        // const storagesResponse = await storagesService.getAll();
        const classificationsResponse = await classificationsService.getAll();

        // if (storagesResponse.ok && storagesResponse.data.status != "Fail") {
        //     const storages = storagesResponse.data.data.map(i => ({ value: i.storageId, label: i.name }));
        //     this.setState({ storages: [{ value: 0, label: "TODOS" }, ...storages] });
        // }

        if (classificationsResponse.ok && classificationsResponse.data.status != "Fail") {
            const classifications = classificationsResponse.data.data.map(i => ({
                value: i.clasificationId, label: i.name, storageId: i.storageId
            }));
            this.setState({ classifications: [{ value: 0, label: "TODOS" }, ...classifications] });
        }
    }

    getPresentationName = (itemPresentation) => {
        if (itemPresentation.itemDescription != itemPresentation.description)
            return `${itemPresentation.itemDescription} ** ${itemPresentation.description}`;
        else
            return itemPresentation.itemDescription;
    }

    getRowTotal = (detail) => {
        if (!detail.suppliedQuantity || isNaN(detail.suppliedQuantity)) {
            detail.newTotal = 0;
            return 0;
        }

        const newValue = parseFloat(detail.suppliedQuantity);
        const newTotal = (newValue * detail.price).toFixed(2);
        detail.newTotal = newTotal;
        return newTotal;
    }

    renderRowInput = (row) => {
        return (
            <TextField
                inputRef={i => this[`input${row.index}`] = i}
                type="text"
                value={row.suppliedQuantity}
                onChange={(e) => {
                    const value = e.target.value;

                    if (isNaN(value))
                        return;

                    const details = this.state.requisition.supplierRequisitionDetails;
                    const detail = details.find(x => x.id == row.id);
                    detail.suppliedQuantity = e.target.value;
                    this.getRowTotal(detail);
                    this.setState(ps => ({ requisition: { ...ps.requisition, supplierRequisitionDetails: details } }));
                }}
                onKeyPress={async (e) => {
                    if (e.key == "Enter") {
                        e.preventDefault();
                        this.nextFocus(row.index);
                    }
                }}
                onKeyDown={e => {
                    if (e.keyCode == 38) { //Up
                        e.preventDefault();
                        this.previousFocus(row.index);
                    }
                    else if (e.keyCode == 40) { //Down
                        e.preventDefault();
                        this.nextFocus(row.index);
                    }
                }}
            />
        );
    }

    renderRowCommentInput = (row) => {
        return <TextField
            type="text"
            value={row.comments}
            multiline
            fullWidth
            margin="dense"
            rowsMax="4"
            onChange={(e) => {
                const details = this.state.requisition.supplierRequisitionDetails;
                const detail = details.find(x => x.id == row.id);
                detail.comments = e.target.value;
                this.setState(ps => ({ requisition: { ...ps.requisition, supplierRequisitionDetails: details } }));
            }}
               />;
    }

    save = async (skipSavedNotification) => {
        if (this.state.loading)
            return;

        const {
            requisition,
            storageId
        } = this.state;

        let items = [...requisition.supplierRequisitionDetails];

        if (storageId && storageId != 0) {
            items = items.filter(i => i.supplierItem.storageId == storageId);
        }

        let emptyItems = items.filter(x => x.suppliedQuantity == "");
        if (emptyItems.length) {
            Swal.fire(
                'Error',
                'Hay articulos sin cantidad ingresada',
                'error'
            );

            return;
        }

        let itemsWithDifference = items.filter(item => this.itemHasDifference(item));
        if (itemsWithDifference.length) {
            let confirmed = await Swal.fire({
                title: 'Confirmación',
                text: "Hay articulos con diferencias en cantidad surtida contra solicitada",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar'
            });

            if (!confirmed.value)
                return;
        }

        this.setState({ loading: true });
        const response = await requisitionsService.saveQuantities({
            supplierRequisitionId: requisition.supplierRequisitionId,
            supplierRequisitionItems: requisition.supplierRequisitionDetails
                .filter(x => x.suppliedQuantity != "")
                .map(i => ({

                    supplierRequisitionDetailId: i.id,
                    suppliedQuantity: i.suppliedQuantity || 0,
                    comments: i.comments
                }))
        }).catch(() => {
            this.props.addNotification({ message: "No se pudo guardar información de requisición", options: { variant: "error" } });
            this.setState({ loading: false });
        });

        this.setState({ loading: false });

        if (!response.ok || !response.data.data.result) {
            this.props.addNotification({ message: "No se pudo guardar información de requisición", options: { variant: "error" } });
            return false;
        }

        if (!skipSavedNotification)
            this.props.addNotification({ message: "Requisición guardada correctamente", options: { variant: "success" } });

        return true;
    }

    confirm = async () => {
        const {
            requisition
        } = this.state;

        if (requisition.supplierRequisitionDetails
            .filter(x =>
                x.storageId == this.state.storageId &&
                x.suppliedQuantity == "")
            .length) {
            this.props.addNotification({ message: "Para confirmar todos los articulos deben tener un valor, al menos 0", options: { variant: "error" } });
            return;
        }

        const saved = await this.save(true);

        if (!saved)
            return;

        const response = await requisitionsService.confirm({
            supplierRequisitionId: requisition.supplierRequisitionId,
            plasticBoxes: requisition.plasticBoxes,
            storageId: this.state.storageId
        }).catch(() => {
            this.props.addNotification({ message: "No se pudo confirmar requisición", options: { variant: "error" } });
        });

        if (!response.ok || !response.data.data.result) {
            this.props.addNotification({ message: "Requisición no confirmada", options: { variant: "error" } });
            return false;
        }

        this.props.addNotification({ message: "Requisición confirmada", options: { variant: "success" } });
        this.props.history.push("/confirm-requisitions");
    }

    getRowStyle = (row) => {


        if (row.suppliedQuantity === undefined || row.suppliedQuantity === null || row.suppliedQuantity === "") {
            return {
                backgroundColor: "rgba(251, 255, 0, 0.3)"
            };
        }
        else if (this.itemHasDifference(row)) {
            return {
                backgroundColor: "rgba(255, 0, 0, 0.3)"
            };
        }
        else {
            return {
                backgroundColor: "rgba(0, 255, 0, 0.3)"
            };
        }
    }

    itemHasDifference = (row) => {
        let maxWeight = row.itemPresentation.maxWeight;
        // if (maxWeight)
        //     maxWeight = maxWeight / 100;

        return (row.suppliedQuantity < (row.quantity - maxWeight) || row.suppliedQuantity > (row.quantity + maxWeight));
    }

    previousFocus = (index) => {
        const prevFocus = index - 1;

        if (prevFocus < 0) {
            return;
        }

        if (this[`input${prevFocus}`] != null) {
            const input = this[`input${prevFocus}`];
            input.focus();
            input.setSelectionRange(0, input.value.length);
        }
        else {
            this.previousFocus(prevFocus);
        }
    }

    nextFocus = (index) => {
        let {
            requisition: {
                supplierRequisitionDetails: details
            }
        } = this.state;

        let nextFocus = index + 1;

        if (nextFocus > details.length) {
            return;
        }

        if (this[`input${nextFocus}`] != null) {
            const input = this[`input${nextFocus}`];
            input.focus();
        }
        else {
            this.nextFocus(nextFocus);
        }
    }

    renderTabs = () => {
        const {
            tab,
        } = this.state;

        return (
            <>
                <AppBar position="static" color="default">
                    <Tabs
                        value={tab}
                        onChange={(e, tab) => {
                            this.setState({ tab });
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab
                            icon={<InfoRounded />}
                            {...tabProps(0)}
                        />
                    </Tabs>
                </AppBar>
                <TabPanel value={tab} index={0} boxStyle={{ paddingTop: "0px" }}>
                    {this.renderForm()}
                </TabPanel>
            </>
        );
    }

    renderFilters = () => {
        const {
            storageId,
            classificationId,
            classifications,
            classificationIds,
            searchItemText,
        } = this.state;

        const {
            classes
        } = this.props;

        const classificationsIdsArray = Array.from(classificationIds);

        let tmpClassifications = [...(classifications || [])];
        if (storageId)
            tmpClassifications = tmpClassifications.filter(x => x.storageId == storageId || x.value == 0);

        if (classificationsIdsArray && classificationsIdsArray.length) {
            tmpClassifications = tmpClassifications.filter(x => classificationsIdsArray.includes(x.value) || x.value == 0);
        }
        else {
            tmpClassifications = tmpClassifications.filter(x => x.value == 0);
        }

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                {/* <Grid item xs={12} md={6} lg={4} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Almacen</InputLabel>
                        <Select
                            value={storageId}
                            onChange={(selected) => {
                                this.setState({ storageId: selected.value });
                            }}
                            options={tmpStorages}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid> */}
                <Grid item xs={12} md={6} lg={4} alignContent="center">
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Clasificación</InputLabel>
                        <Select
                            key={storageId}
                            value={classificationId}
                            onChange={(selected) => {
                                this.setState({ classificationId: selected.value });
                            }}
                            options={tmpClassifications}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={4} alignContent="center" />
                <Grid item xs={12} md={6} lg={4} alignContent="center" >
                    <Grid
                        direction="column"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Buscar por artículo</InputLabel>
                        <TextField
                            value={searchItemText}
                            style={{ width: '100%' }}
                            onChange={(e) => {
                                const value = e.target.value;
                                this.setState({ searchItemText: value });
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderForm = () => {
        const {
            classes
        } = this.props;

        const {
            requisition,
            storageId,
            classificationId,
            searchItemText,
        } = this.state;

        let items = [];

        if (requisition.supplierRequisitionDetails) {
            items = requisition.supplierRequisitionDetails;
        }

        if (storageId && storageId != 0) {
            items = items.filter(i => i.supplierItem.storageId == storageId);
        }

        if (classificationId && classificationId != 0) {
            items = items.filter(i => i.supplierItem.clasificationId == classificationId);
        }

        if (searchItemText) {
            items = items.filter(i => i.itemPresentation.sapCode.startsWith(searchItemText) || i.itemPresentation.description.toLowerCase().includes(searchItemText.toLowerCase()));
        }

        return (
            <Formik
                enableReinitialize
                initialValues={requisition}
                onSubmit={async (values, { setSubmitting }) => {
                    await this.save(values);
                    setSubmitting(false);
                }}
                render={({ submitForm, isSubmitting }) => (
                    <SaveContainer saveContent={submitForm}>
                        <Form>
                            <Grid container className={classes.root} spacing={1}>
                                <Hidden mdDown>
                                    <Grid item xsOnly className={classes.item} md={7} lg={8}>
                                        <Field
                                            type="text"
                                            label="Folio"
                                            name="folio"
                                            margin="normal"
                                            component={FormikTextField}
                                            fullWidth
                                            disabled
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                </Hidden>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    xs={12}
                                    md={4}
                                    lg={3}
                                >
                                    <Button
                                        size="small"
                                        component="button"
                                        variant="text"
                                        color="default"
                                        className={classes.button}
                                        onClick={() => this.save(false)}
                                    >
                                        <SaveOutlined className={classes.leftIcon} />
                                        <Typography style={{ fontSize: '10px', marginLeft: '3px' }}>Guardar</Typography>
                                    </Button>
                                    {
                                        requisition && requisition.statusId < 4 &&
                                        <Button
                                            size="small"
                                            component="button"
                                            variant="outlined"
                                            color="default"
                                            className={classes.button}
                                            onClick={() => {
                                                if (!requisition.plasticBoxes || requisition.plasticBoxes <= 0) {
                                                    this.props.addNotification({ message: "Se necesita ingresar el numero de cajas plasticas", options: { variant: "error" } });
                                                }
                                                else {
                                                    this.setState({ isConfirming: true });
                                                }
                                            }}
                                            disabled={isSubmitting}
                                            style={{ marginLeft: '0px' }}
                                        >
                                            <CheckOutlined className={classes.leftIcon} />
                                            <Typography style={{ fontSize: '10px', marginLeft: '3px' }}>
                                                {"Confirmar"}
                                            </Typography>
                                        </Button>
                                    }
                                </Grid>
                                <Hidden smUp>
                                    <Grid item xs={12} className={classes.item}>
                                        <Field
                                            name="folio"
                                            render={(form) => this.renderLabel(form, "Folio")}
                                        />
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12} md={4} lg={3} className={classes.itemSpacing}>
                                    <Field
                                        name="lunchRoomName"
                                        render={(form) => this.renderLabel(form, "Unidad")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={3} className={classes.itemSpacing}>
                                    <Field
                                        name="deliveryDateFormatted"
                                        render={(form) => this.renderLabel(form, "Dia de entrega")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={3} className={classes.itemSpacing}>
                                    <Field
                                        name="type"
                                        render={(form) => this.renderLabel(form, "Tipo de surtido")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={3} className={classes.itemSpacing}>
                                    <Field
                                        name="eventId"
                                        render={(form) => this.renderLabel(form, "Evento")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={3} className={classes.itemSpacing}>
                                    <Field
                                        name="totalRequested"
                                        render={(form) => this.renderLabel(form, "Total solicitado", 'currency')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} className={classes.itemSpacing}>
                                    <Field
                                        name="total"
                                        render={() => (
                                            <div className={classes.selectRoot}>
                                                <InputLabel>Total surtido</InputLabel>
                                                <TextField
                                                    value={requisition.supplierRequisitionDetails ? "$ " + requisition.supplierRequisitionDetails.reduce((sum, i) => sum + parseFloat(i.newTotal), 0).toFixed(2) : "$ 0"}
                                                    style={{ width: '100%' }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </div>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} className={classes.itemSpacing}>
                                    <Field
                                        name="plasticBoxes"
                                        render={() => (
                                            <div className={classes.selectRoot}>
                                                <InputLabel>Cajas plasticas</InputLabel>
                                                <TextField
                                                    type="number"
                                                    value={requisition.plasticBoxes}
                                                    style={{ width: '100%' }}
                                                    onChange={(e) => {
                                                        if (e.target != null && e.target.value != null) {
                                                            const value = e.target.value;
                                                            this.setState(ps => ({ requisition: { ...ps.requisition, plasticBoxes: value } }));
                                                        }
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </div>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                    <Grid
                                        container
                                        spacing={3}
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        style={{ paddingLeft: '15px', paddingRight: "15px" }}
                                    >
                                        {this.renderFilters()}
                                        <Table
                                            columns={this.columns}
                                            items={items}
                                            options={{
                                                paging: false,
                                                search: false,
                                                toolbar: false,
                                                rowStyle: row => this.getRowStyle(row)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    </SaveContainer>
                )}
            />
        );
    }

    renderLabel = (form, label, type) => {
        const {
            classes
        } = this.props;

        return (
            <div className={classes.selectRoot}>
                <InputLabel>{label}</InputLabel>
                <TextField
                    value={type == 'currency' ? "$ " + parseFloat(form.field.value || 0).toFixed(2) : type == "number" ? parseFloat(form.field.value || 0).toFixed(2) : form.field.value}
                    style={{ width: '100%' }}
                />
            </div>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        const {
            loading,
            isConfirming,
            requisition,
        } = this.state;

        let isLoading = loading;
        return (
            <DashboardLayout title={requisition && requisition.statusId >= 4 ? "Editar requisición" : "Confirmar requisición"}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {
                                    isConfirming &&
                                    <ConfirmDialog
                                        text={`¿ Seguro que desea guardar y confirmar la requisición ?`}
                                        open
                                        onClose={() => this.setState({ isConfirming: false })}
                                        onConfirm={this.confirm}
                                    />
                                }
                                {
                                    isLoading && <CircularProgress />
                                }
                                {
                                    !isLoading &&
                                    this.renderTabs()
                                }
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(WithNotifications(WithSession(WithTicket(withStyles(styles)(ConfirmRequisition)))));