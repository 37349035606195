import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import classNames from "classnames";
import Modal from 'components/Modal';
import ViewRequisition from "views/ViewRequisition";
// UI Components
import {
    withStyles,
    Grid,
    // CircularProgress,
    InputLabel,
    TextField,
    FormControlLabel,
    FormGroup,
    Switch,
    IconButton
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Select } from 'components';
import { DatePicker } from "@material-ui/pickers";

//Services
import { WithTicket, WithSession, WithNotifications } from "hoc";
import requisitionsService from "services/requisitionsService";

//Assets
import styles from './styles';
import { EditOutlined } from '@material-ui/icons';

class SearchRequisitions extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        selectedItems: [],
        requisitions: [],
        startDate: Moment().startOf('week').toDate(),
        endDate: Moment.utc().endOf('week').toDate(),
        statusId: 1,
        loading: false
    };

    statuses = [
        { value: 0, label: "Todos" },
        { value: 1, label: "Por Recibir" },
        { value: 2, label: "Recibidos por revisar" },
        { value: 3, label: "Recibidos" },
        { value: 4, label: "Confirmados" },
        { value: 5, label: "Embarcados" },
        // { value: 6, label: "Cancelados" },
        { value: 7, label: "Entregados" },
        { value: 8, label: "Facturados" }
    ]

    componentDidMount = () => {
        this.search();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            startDate,
            endDate,
            statusId,
            isExtra
        } = this.state;

        if (prevState.endDate != endDate && endDate < startDate)
            this.setState({ startDate: endDate }, this.search);
        else if (prevState.startDate != startDate && startDate > endDate)
            this.setState({ endDate: startDate }, this.search);
        else if (prevState.startDate != startDate || prevState.endDate != endDate || prevState.statusId != statusId || prevState.isExtra != isExtra)
            this.search();
    }

    getStatus = (row) => {
        const status = this.statuses.find(x => x.value == row.statusId);
        return status ? status.label.replace(/s*$/, "") : "";
    }

    search = async () => {
        const {
            startDate,
            endDate,
            statusId,
            folio,
            isExtra
        } = this.state;

        this.setState({ loading: true });

        const model = {
            status: statusId ? statusId : null,
            endDate,
            startDate,
            folio,
            isExtra
        };

        const response = await requisitionsService.search(model);
        this.setState({ loading: false });

        if (response.ok && Array.isArray(response.data.data)) {
            this.setState({ requisitions: response.data.data });
        }
        else {
            this.props.addNotification({ message: "Error realizando busqueda", options: { variant: "error" } });
        }
    }

    getTitle = () => {
        return "Buscador";
    }

    renderFilters = () => {
        const {
            statusId,
            startDate,
            endDate,
            folio,
            isExtra
        } = this.state;

        const {
            classes
        } = this.props;

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha inicial</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={startDate}
                            onChange={startDate => this.setState({ startDate })}
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Fecha final</InputLabel>
                        <DatePicker
                            format="DD MMMM [del] YYYY"
                            value={endDate}
                            onChange={endDate => this.setState({ endDate })}
                            autoOk
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Estado</InputLabel>
                        <Select
                            value={statusId}
                            onChange={(selected) => {
                                this.setState({ statusId: selected.value });
                            }}
                            options={this.statuses}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <InputLabel>Folio</InputLabel>
                    <TextField
                        type="text"
                        value={folio}
                        style={{ width: '100%' }}
                        onKeyPress={e => {
                            if (e.charCode === 13) {
                                this.search();
                            }
                        }}
                        onChange={(e) => {
                            if (e.target != null && e.target.value != null) {
                                this.setState({ folio: e.target.value });
                            }
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isExtra}
                                    onChange={e => {
                                        this.setState({ isExtra: e.target.checked });
                                    }}
                                    color="primary"
                                />
                            }
                            label="Solo Extras"
                        />
                    </FormGroup>
                </Grid>
            </Grid>
        );
    }

    getTableColumns = () => {
        let columns = [
            {
                title: "Folio",
                field: 'folio',
                render: (row) => (
                    <Link
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            this.setState({ requisitionIdToShow: row.supplierRequisitionId });
                        }}
                    >
                        {row.folio}
                    </Link>
                )
            },
            {
                title: "Unidad",
                field: 'lunchRoomName'
            },
            {
                title: "Fecha de entrega",
                field: 'deliveryDate',
                render: row => {
                    return (
                        <span>{Moment.utc(row.deliveryDate).format("DD/MM/YYYY")}</span>
                    );
                }
            },
            {
                title: "Estado",
                field: 'status',
                render: (row) => <span>{this.getStatus(row)}</span>
            },
            {
                title: "Es Extra",
                field: ' ',
                render: (row) => <span>{row.isExtra ? "Si" : ""}</span>
            }
        ];

        if (this.props.session && this.props.session.roleId == 6) {
            columns = [
                ...columns,
                {
                    title: " ",
                    field: 'Acciones',
                    render: (row) => {
                        if (row.statusId < 4) {
                            return null;
                        }
                        
                        return (
                            <IconButton
                                onClick={() => {
                                    this.props.history.push(`/confirm-requisitions/${row.supplierRequisitionId}/details`);
                                }}
                            >
                                <EditOutlined />
                            </IconButton>
                        );
                    }
                }
            ];
        }

        return columns;
    }

    renderTable = () => {
        const {
            requisitions,
            selectedItems,
            copiyng,
            loading
        } = this.state;

        let items = requisitions;

        const tableColumns = this.getTableColumns();

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    {this.renderFilters()}
                    <Table
                        columns={tableColumns}
                        items={copiyng ? selectedItems : items}
                        options={{
                            pageSize: copiyng ? 100 : 10,
                            toolbar: false,
                            search: false
                        }}
                        onSelectionChange={(selectedItems) => {
                            this.setState({ selectedItems });
                        }}
                        isLoading={loading}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderViewModal = () => {
        const {
            requisitionIdToShow
        } = this.state;

        return (
            <Modal
                title="Detalle de requisición"
                isOpen={this.state.requisitionIdToShow != null}
                onRequestClose={() => {
                    this.setState({ requisitionIdToShow: null });
                }}
                style={{ width: '95%', height: '85%' }}
            >
                <ViewRequisition
                    id={requisitionIdToShow}
                    hideLayout
                    onClose={() => this.setState({ requisitionIdToShow: null })}
                />
            </Modal>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {this.renderViewModal()}
                                {this.renderTable()}
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithNotifications(WithSession(WithTicket(withStyles(styles)(SearchRequisitions))))));